import React from "react";
import { connect } from "react-redux";
import get from "lodash/get";
import Helmet from "react-helmet";
import Container from "../components/container";
import ContainerFluid from "../components/container-fluid";
import Hero from "../components/hero";
import Sponsors from "../components/sponsors";
import { graphql } from "gatsby";
import MakeDifference from "../components/home/make-a-difference";
import Spectator from "../components/home/spectator";
import Prizes from "../components/home/prizes";
import Chance from "../components/home/fighting-chance";
import Layout from "../components/layout";
import styles from "../components/sponsors.module.css";
import AnnualSponsors from "../components/home/annual-sponsors";

class RootIndex extends React.Component {
  componentDidMount() {
  }

  render() {
    const siteTitle = get(this, "props.data.site.siteMetadata.title")
    const siteImage = get(this, "props.data.site.siteMetadata.image")
    const posts = get(this, "props.data.allContentfulBlogPost.edges")
    const slides = get(this, "props.data.allContentfulSlide.edges")
    const sponsors = get(this, "props.data.allContentfulSponsor.edges")
    const homeFundsSection = get(
      this,
      "props.data.allContentfulHomeFundsRaisedSection.nodes"
    )

    return (
      <Layout>
        <Helmet>
          <title>{siteTitle}</title>
          <meta name="title" content={siteTitle} />
          <meta
            name="description"
            content="Faraja’s annual White Water Rafting Challenge is an annual event organized by Faraja Cancer Support Trust in partnership with Savage Wilderness Camp."
          />
          <meta property="og:title" content={siteTitle} />
          <meta
            property="og:description"
            content="Faraja’s annual White Water Rafting Challenge is an annual event organized by Faraja Cancer Support Trust in partnership with Savage Wilderness Camp."
          />
          <meta
            property="og:image"
            content={siteImage}
          />
          <meta
            property="og:url"
            content="https://farajaraftingchallenge.org/"
          />
        </Helmet>

        <Hero data={slides} isHome={true} />

        <section className="sponsors">
          {posts?.map(({node}) => {
            if (node.slug === "annual-sponsors") {
              return (
                <section key={node.slug}>
                  <AnnualSponsors article={node} data={homeFundsSection} />
                </section>
              )
            }
          })}
        </section>
        
          <section className="sponsors">
            <div className="text-center">
            <h4 className={styles.headline}>
              Our Bronze Sponsors were: NCBA, Great Rift Valley Lodge (GRVL), Diani Reef Beach & Resort,
              <br/>
              Muthu Lake Naivasha Country Club
            </h4>
          </div>

          <div className="container">
              {posts?.map(({node}) => {
                return (
                  node.slug === 'annual-sponsors' && (
                    // <SponsorCard key={node._id} data={node} />
                    <div className="row" key={node.slug}>
                      {/* <div className={`col-12 col-md-12 ${styles.winner_two}`}>
                        <div className={`card ${styles.logo_card}`}>
                            <img className="card-img-top" src="/NCBA-logo.jpg" alt="Faraja Rafting Challenge" />
                        </div>
                      </div> */}
                      <div className={`col-12 col-md-3 ${styles.winner_three}`}>
                        <div className={`card ${styles.logo_card}`}>
                            <img src={node?.photos[4]?.sizes?.src} alt="Faraja Rafting Challenge" />
                        </div>
                      </div>
                      <div className={`col-12 col-md-3 ${styles.winner_two}`}>
                          <div className={`card ${styles.logo_card}`}>
                              <img src={node?.photos[2]?.sizes?.src} alt="Faraja Rafting Challenge" />
                          </div>
                      </div>
                      <div className={`col-12 col-md-3 ${styles.winner_three}`}>
                        <div className={`card ${styles.logo_card}`}>
                            <img src={node?.photos[3]?.sizes?.src} alt="Faraja Rafting Challenge" />
                        </div>
                      </div>
                    <div className={`col-12 col-md-3 ${styles.winner_two}`}>
                      <div className={`card ${styles.logo_card}`}>
                          <img className="card-img-top" src={node?.photos[5]?.sizes?.src} alt="Faraja Rafting Challenge" />
                      </div>
                    </div>
                    </div>
                  )
                )
              })}
            </div>
            {/* <div className="row d-flex justify-content-center mb-4">
              <div className={`col-12 col-md-12 ${styles.logo_one}`}>
                <img src="/JumiaPay.png" alt="Faraja Rafting Challenge" />
              </div>
            </div>
            <div className="row">
              <div className={`col-6 col-md-6 ${styles.logo_two}`}>
                <div className="img-wrap">
                  <img src="/NCBA-logo.jpg" alt="Faraja Rafting Challenge" />
                </div>
              </div>

              <div className={ `col-6 col-md-6 ${styles.logo_three}`}>
                <img src="/GRVL.png" alt="Faraja Rafting Challenge" />
              </div>
            </div> */}
          </section>

        

        <div className="sections-list">
          {posts.map(({ node }) => {
            if (node.slug === "make-splash-make-a-difference") {
              return (
                <section key={node.slug}>
                  <MakeDifference article={node} data={homeFundsSection} />
                </section>
              )
            }
            if (node.slug === "spectators") {
              return (
                <section key={node.slug}>
                  {" "}
                  <ContainerFluid>
                    {" "}
                    <Spectator article={node} />
                  </ContainerFluid>
                </section>
              )
            }
            if (node.slug === "prizes") {
              return (
                <section key={node.slug}>
                  {" "}
                  <ContainerFluid>
                    <Prizes article={node} />
                  </ContainerFluid>
                </section>
              )
            }
            if (node.slug === "fighting-chance") {
              return (
                <section key={node.slug}>
                  {" "}
                  <ContainerFluid>
                    <Chance article={node} />
                  </ContainerFluid>
                </section>
              )
            }
          })}
        </div>

        <section className="sponsors">
          <ContainerFluid>
            {sponsors && <Sponsors data={sponsors}></Sponsors>}
          </ContainerFluid>
        </section>
        
      </Layout>
    )
  }
}

const mapStateToProps = state => ({
  user: state.user
})

const mapDispatchToProps = () => {
  return {}
}

export default connect(mapStateToProps, mapDispatchToProps())(RootIndex)

export const pageQuery = graphql`
  query HomeQuery {
    site {
      siteMetadata {
        title
        image
      }
    }
    allContentfulHomeFundsRaisedSection {
      nodes {
        topHeadlineText
        heading
        bodyText {
          content {
            content {
              value
            }
          }
        }
      }
    }
    allContentfulBlogPost(sort: { fields: [sort], order: ASC }) {
      edges {
        node {
          title
          slug
          publishDate(formatString: "MMMM Do, YYYY")
          tags
          heroImage {
            sizes(maxWidth: 350, maxHeight: 196, resizingBehavior: SCALE) {
              ...GatsbyContentfulSizes_withWebp
            }
          }
          photos {
            title
            description
            sizes(maxWidth: 350, maxHeight: 196, resizingBehavior: SCALE) {
              ...GatsbyContentfulSizes_withWebp
            }
          }
          body {
            childMarkdownRemark {
              html
            }
          }
          description {
            childMarkdownRemark {
              html
            }
          }
        }
      }
    }

    allContentfulSponsor(
      filter: { contentful_id: { eq: "4ELHWSP5K3vzS2xTMepLWS" } }
      sort: { fields: [name], order: DESC }
    ) {
      edges {
        node {
          name
          logos {
            title
            sizes(maxWidth: 250, resizingBehavior: SCALE) {
              ...GatsbyContentfulSizes_withWebp
            }
          }
        }
      }
    }
    allContentfulSlide(sort: { fields: [title], order: DESC }) {
      edges {
        node {
          id
          title
          hero {
            sizes(quality: 100, maxWidth: 4160) {
              ...GatsbyContentfulSizes_withWebp
            }
          }
        }
      }
    }
  }
`
